<svg
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	x="0px"
	y="0px"
	viewBox="0 0 17.875 7.062"
	enable-background="new 0 0 17.875 7.062"
	xml:space="preserve"
	class={$$props.class}
>
	<circle fill="currentColor" cx="4.375" cy="3.575" r="2.5" />
	<circle fill="currentColor" cx="13.375" cy="3.575" r="2.5" />
</svg>
